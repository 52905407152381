<template>
  <div class="agenda-item" :class="{ 'agenda-item--expired': expired }">
    <component :is="expired ? 'div' : 'AppRouteLink'" :route-to="!expired ? routeTo : null">
      <div class="image" :style="`background-image:url(${mediaUrl})`" />
      <div v-if="item.title" class="title">{{ item.title }}</div>
      <div v-if="type" class="small-grey agenda-type">{{ type }}</div>

      <div class="d-flex">
        <div v-if="item.destination && item.destination.title" class="small-grey">
          {{ item.destination.title }}
        </div>
        <div v-if="item.destination && item.destination.country" class="small-grey">
          , {{ item.destination.country }}
        </div>
      </div>

      <div v-if="startDate || endDate" class="d-flex">
        <div v-if="startDate" class="small-grey">{{ startDate }}</div>

        <template v-if="endDate && endDate !== startDate">
          <div class="small-grey mx-1">-</div>
          <div class="small-grey">{{ endDate }}</div>
        </template>
      </div>
    </component>
  </div>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';
import { formatDate } from '@/helpers/dateTimeHelper';

import AppRouteLink from '@/components/partials/AppRouteLink';

export default {
  name: 'AgendaItem',
  components: { AppRouteLink },
  props: {
    expired: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    endDate() {
      const endDate = this.item.date && this.item.date[0] ? this.item.date[0].end_date : this.item.end_date;

      return formatDate(endDate);
    },
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.item);
    },
    routeTo() {
      return getEntityRoute(this.item);
    },
    startDate() {
      const startDate = this.item.date && this.item.date[0] ? this.item.date[0].start_date : this.item.start_date;

      return formatDate(startDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.agenda-item {
  width: calc(100% / 3 - 30px);
  margin: 0 15px 35px;

  @media (max-width: 991px) {
    width: calc(100% / 2 - 30px);
  }

  @media (max-width: 575px) {
    width: 100%;
    margin-bottom: 25px;
  }

  &:not(&--expired):hover {
    .image {
      transform: scale(0.95);
    }
  }

  .image {
    width: 100%;
    height: 250px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #d9d9d9;
    transition: all 0.3s linear;
  }

  .title {
    color: #353535;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.85px;
    text-transform: uppercase;
    line-height: 30px;
    margin-top: 15px;

    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .agenda-type {
    margin-bottom: 10px;
    text-transform: capitalize;

    @media (max-width: 575px) {
      margin-bottom: 5px;
    }
  }
}

.expired-style {
  .agenda-item {
    .image {
      $expiredColor: #5d5d5d;
      position: relative;
      background-color: $expiredColor;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: rgba($expiredColor, 0.8);
      }
    }
  }
}
</style>
