<template>
  <div class="page-wrap">
    <div class="loader" v-if="isShowLoader">
      <AppLoader />
    </div>

    <div class="main-content profile-data-page">
      <BackHeader is-show-title is-static title="My Agenda" :with-filter="!isAgendaEmpty" />
      <div class="container">
        <div class="data-page-top align-items-center mt-5 mt-sm-0 d-none d-sm-flex">
          <h1 class="page-title mb-0" data-aos="fade-up">My Agenda</h1>
          <AppDataFilterOpenButton v-if="!isAgendaEmpty" @click="handleOpenFilter" />
        </div>

        <div v-if="myAgenda.upcoming.length && !isShowLoader" class="mt-5 mt-sm-0">
          <div v-for="(item, idx) in myAgenda.upcoming" :key="`${item.date}_${idx}`">
            <template v-if="item.objects.length">
              <h2 class="agenda__title" data-aos="fade-up">{{ formatGroupDate(item.date) }}</h2>
              <div class="agenda__container">
                <AgendaItem
                  v-for="entity in item.objects"
                  :key="`${entity.object.id}_${entity.typeForHuman}`"
                  data-aos="fade-up"
                  :item="entity.object"
                  :type="entity.typeForHuman"
                />
              </div>
            </template>
          </div>
        </div>

        <div v-if="myAgenda.expired.length && !isShowLoader">
          <h2 class="agenda__title" data-aos="fade-up">Expired</h2>
          <div class="agenda__container expired-style">
            <AgendaItem
              v-for="data in myAgenda.expired"
              :key="`${data.object.id}_${data.typeForHuman}`"
              data-aos="fade-up"
              :expired="data.isExpired"
              :item="data.object"
              :type="data.typeForHuman"
            />
          </div>
        </div>
        <div class="blank_page" v-if="!isShowLoader && isAgendaEmpty">Nothing has been added to this yet.</div>
      </div>
    </div>

    <AppDataFilterMain
      v-model="filterValues"
      :filter-groups="filterGroups"
      :init-open="isAppDataFilterOpened"
      :is-mobile="isMobileScreen"
      @input="handleChangeFilterValues"
      @open="handleOpenFilter"
      @close="handleCloseFilter"
    />
  </div>
</template>

<script>
import { areRouteQueriesDifferent } from '@/helpers/routerHelper';
import MyAgendaDataFilterHelper from '@/helpers/data-filter/MyAgendaDataFilterHelper';
import { formatDate } from '@/helpers/dateTimeHelper';

import myAgendaQuery from '@/graphql/me/agenda/MyAgenda.query.gql';
import myAgendaPageFilterQuery from '@/graphql/me/agenda/MyAgendaPageFilter.query.gql';

import BackHeader from '@/components/partials/BackHeader.vue';
import AgendaItem from '@/components/partials/AgendaItem.vue';
import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton.vue';
import AppDataFilterMain from '@/components/data-filter/AppDataFilterMain.vue';

export default {
  name: 'MyAgendaPage',
  components: {
    AppDataFilterMain,
    AppDataFilterOpenButton,
    BackHeader,
    AgendaItem,
  },
  metaInfo: {
    title: 'My Agenda',
  },
  data() {
    return {
      myAgenda: { expired: [], upcoming: [] },
      isShowLoader: true,
      filterGroups: [],
      filterValues: {},
      changingRouteAfterFilterUpdated: false,
    };
  },
  computed: {
    filterQueryVariables() {
      return MyAgendaDataFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues);
    },
    isAppDataFilterOpened() {
      return !!this.$store.state.isAppDataFilterOpened;
    },
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
    isAgendaEmpty() {
      let isEmpty = !this.myAgenda.expired.length;
      const upcomingItems = this.myAgenda.upcoming || [];

      for (let i = 0; i < upcomingItems.length; i++) {
        if (upcomingItems[i].objects && upcomingItems[i].objects.length) {
          isEmpty = false;
          break;
        }
      }

      return isEmpty;
    },
  },
  created() {
    this.filterValues = MyAgendaDataFilterHelper.getFilterValuesFromRouteQuery(this.$route.query);
    this.fetchMyAgenda();
    this.getFilterData();
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.changingRouteAfterFilterUpdated) {
      this.filterValues = MyAgendaDataFilterHelper.getFilterValuesFromRouteQuery(to.query);
    }
    this.fetchMyAgenda();
    this.changingRouteAfterFilterUpdated = false;
    next();
  },
  methods: {
    fetchMyAgenda() {
      this.isShowLoader = true;

      this.$apollo
        .query({
          query: myAgendaQuery,
          fetchPolicy: 'no-cache',
          variables: {
            ...this.filterQueryVariables,
          },
        })
        .then(({ data = { myAgenda: {} } }) => {
          this.myAgenda = data.myAgenda;
          this.myAgenda.expired = this.myAgenda.expired.filter((item) => item.object);
          this.isShowLoader = false;
        })
        .catch(() => {
          this.isShowLoader = false;
        });
    },
    getFilterData() {
      return this.$apollo
        .query({
          query: myAgendaPageFilterQuery,
          fetchPolicy: 'no-cache',
        })
        .then(({ data = { myAgendaPageFilter: {} } }) => {
          this.filterGroups = MyAgendaDataFilterHelper.prepareFilterGroups(data.myAgendaPageFilter);
        });
    },
    handleOpenFilter() {
      this.$store.dispatch('toggleAppDataFilter', true);
    },
    handleCloseFilter() {
      this.$store.dispatch('toggleAppDataFilter', false);
    },
    handleChangeFilterValues() {
      const query = MyAgendaDataFilterHelper.setFilterValuesToRouteQuery(this.filterValues);

      if (areRouteQueriesDifferent(this.$route.query, query)) {
        this.changingRouteAfterFilterUpdated = true;
        this.$router.push({ name: this.$route.name, params: this.$route.params, query });
      }
    },
    formatGroupDate(dateTimeValue) {
      return formatDate(dateTimeValue);
    },
  },
};
</script>

<style scoped lang="scss">
.profile-data-page {
  align-items: flex-start;
}

.blank_page {
  padding-top: 30px;

  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.agenda {
  &__title {
    margin-bottom: 30px;
    color: #353535;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 2.86px;
    text-transform: uppercase;

    @media (max-width: 575px) {
      font-size: 18px;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 30px;

    @media (max-width: 575px) {
      margin-bottom: 15px;
    }
  }
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 5555;
  transform: translate(-50%, -50%);
}
</style>
