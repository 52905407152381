import DataFilterHelper from './DataFilterHelper';
import {
  FILTER_TYPE_DESTINATIONS,
  FILTER_TYPE_MODEL_TYPES,
  FILTER_TYPE_MONTHS,
  FILTER_TYPE_PLACE_IN_TIME,
  FILTER_TYPE_TICKET_REQUEST_STATUS,
  FILTER_TYPE_YEARS,
} from '@/helpers/data-filter/DataFilterTypes';

const placeInTimeOptions = {
  expired: 'expired',
  upcoming: 'upcoming',
};

class MyAgendaDataFilterHelper extends DataFilterHelper {
  defaultValues = {
    [FILTER_TYPE_DESTINATIONS]: [],
    [FILTER_TYPE_MODEL_TYPES]: [],
    [FILTER_TYPE_MONTHS]: [],
    [FILTER_TYPE_PLACE_IN_TIME]: null,
    [FILTER_TYPE_TICKET_REQUEST_STATUS]: null,
    [FILTER_TYPE_YEARS]: [],
  };
  castsToRoute = {
    [FILTER_TYPE_MODEL_TYPES]: 'types',
    [FILTER_TYPE_TICKET_REQUEST_STATUS]: 'status',
  };
  validValuesTypes = {
    array: [
      FILTER_TYPE_DESTINATIONS,
      this.castsToRoute[FILTER_TYPE_MODEL_TYPES],
      FILTER_TYPE_MONTHS,
      FILTER_TYPE_YEARS,
    ],
    number: this.castsToRoute[FILTER_TYPE_TICKET_REQUEST_STATUS],
  };

  /**
   * @param {Object} groupsWithItems
   * @param {FilterGroupItem[]|null} groupsWithItems.destinations
   * @param {FilterGroupItem[]|null} groupsWithItems.months
   * @param {FilterGroupItem[]|null} groupsWithItems.requestTypes
   * @param {FilterGroupItem[]|null} groupsWithItems.ticketRequestStatuses
   * @param {FilterGroupItem[]|null} groupsWithItems.years
   * @returns {FilterGroup[]}
   */
  prepareFilterGroups(groupsWithItems) {
    /**
     * @type {FilterGroup[]}
     */
    const groups = [
      this.addDefaultGroupItems({
        group: groupsWithItems.destinations,
        itemsType: FILTER_TYPE_DESTINATIONS,
        title: 'Destination',
      }),

      this.addDefaultGroupItems({
        group: groupsWithItems.requestTypes,
        itemsType: FILTER_TYPE_MODEL_TYPES,
        title: 'Benefit',
      }),

      this.addDefaultGroupItems({
        group: groupsWithItems.months,
        itemsType: FILTER_TYPE_MONTHS,
        title: 'Month',
      }),

      this.addDefaultGroupItems({
        group: groupsWithItems.years,
        itemsType: FILTER_TYPE_YEARS,
        title: 'Year',
      }),

      this.addRadioGroupItems({
        itemsType: FILTER_TYPE_PLACE_IN_TIME,
        items: [
          {
            title: 'Only Upcoming',
            value: placeInTimeOptions.upcoming,
          },
          {
            title: 'Only Expired',
            value: placeInTimeOptions.expired,
          },
        ],
      }),

      this.addRadioGroupItems({
        itemsType: FILTER_TYPE_TICKET_REQUEST_STATUS,
        items: groupsWithItems.ticketRequestStatuses,
      }),
    ];

    return groups.filter((v) => v);
  }

  prepareFilterValuesToQueryVariables(values = {}, fallback = {}) {
    const transformed = super.prepareFilterValuesToQueryVariables(values, fallback);

    return {
      filters: {
        myAgendaDestinationSlug: transformed[FILTER_TYPE_DESTINATIONS],
        myAgendaMonths: transformed[FILTER_TYPE_MONTHS],
        myAgendaYears: transformed[FILTER_TYPE_YEARS],
        placeInTime: transformed[FILTER_TYPE_PLACE_IN_TIME],
        requestTypes: transformed[FILTER_TYPE_MODEL_TYPES],
        ticketRequestStatus: transformed[FILTER_TYPE_TICKET_REQUEST_STATUS],
      },
    };
  }
}

export default MyAgendaDataFilterHelper.create();
